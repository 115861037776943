import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import HeroBanner from 'components/heroBanner'
import Intro from 'components/intro'
import CopyBlock from 'components/copyBlock'
import CopySplit from 'components/copySplit'
import { TimelineStacked } from 'components/timeline'
import PartnerCarousel from 'components/partnerCarousel'
import Awards from 'components/awards'
import { graphql } from 'gatsby'

const SchoolsPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.schoolsJson.title}</title>
      <meta name="description" content={data.schoolsJson.description} />
    </Helmet>
    <HeroBanner hero={data.schoolsJson.hero} />
    <Intro intro={data.schoolsJson.intro} />
    <TimelineStacked timeline={data.schoolsJson.timeline} />
    <CopyBlock topMargin>
      <CopySplit>
        <h3>Who do we work for?</h3>
        <p>
          We work with small and large businesses alike. Whatever their size, we provide the same high levels of
          support. And even as PCW grows, our mission remains constant: to offer fast, personalised, efficient and
          high-quality IT solutions.
        </p>
      </CopySplit>
      <CopySplit>
        <h3>Why clients love us:</h3>
        <div>
          <h4>Personal approach</h4>
          <p>We provide personalised tech support to meet clients’ individual needs</p>
          <h4>Ongoing support</h4>
          <p>We make regular “check-in calls” to ensure IT systems continue running smoothly</p>
          <h4>Simple process</h4>
          <p>Our ticket system keeps clients in the loop about our progress</p>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Why do you need us?</h3>
        <div>
          <p>
            It doesn’t matter how competent your staff are, or how quickly they work. If your IT systems are outdated,
            you’ll only wind up with frustration, wasted time and poor results. That’s where we come in – helping you to
            purchase, install and maintain the best possible IT infrastructure for your business.
          </p>
          <p>Let us take care of that, and let your business reach its full potential.</p>
        </div>
      </CopySplit>
    </CopyBlock>
    <PartnerCarousel />
    <Awards />
  </Layout>
)

SchoolsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default SchoolsPage

export const query = graphql`
  query SchoolsPageQuery {
    schoolsJson {
      title
      description
      hero {
        heading
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      intro {
        heading
        copy
      }
      timeline {
        heading
        steps {
          order
          heading
          copy
        }
        link
        cta
      }
    }
  }
`
